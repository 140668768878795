/* eslint-disable */
export default {
  id: "solace-java-api",
  componentName: "connectivity-detail-template-v1",
  displayName: "Solace Java API",
  protocol: {
    id: "smf",
    name: "SMF",
    logo: "./images/solace-logo.png",
    usesVPN: true,
  },
  language: {
    name: "Java",
    logo: "./images/java-logo.png",
  },
  links: {
    tutorial: "https://tutorials.solace.dev/java/",
    api:
      "https://docs.solace.com/API-Developer-Online-Ref-Documentation/pubsubplus-java/index.html",
    download: [
      {
        name: "Maven Repository",
        group: "none",
        url: "https://mvnrepository.com/artifact/com.solace/solace-messaging-client",
      },
    ],
  },
  step1: {
    name: "Java API",
    getAPI: [
      {
        title: "Using Gradle",
        code: ['compile("com.solace:solace-messaging-client:1.1.+")'],
      },
      {
        title: "Using Maven",
        code: [
          "&lt;dependency&gt; <br>\
                &nbsp&nbsp &lt;groupId&gt;com.solace&lt/groupId&gt <br>\
                &nbsp&nbsp &lt;artifactId&gt;solace-messaging-client&lt;/artifactId&gt; <br>\
                &nbsp&nbsp &lt;version&gt;[1.1,)&lt;/version&gt; <br>\
                &lt;/dependency&gt",
        ],
      },
      {
        title: "Using Solace Developer Portal",
        description:
          'The Solace Pubsub+ Java API library can be downloaded <a href="https://solace.com/downloads/?fwp_downloads=solace-apis" target="_blank">here</a>. The Java API is distributed as a zip file containing the required JARs.',
      },
    ],
  },
  step2: {
    introduction: [
      "In order to send or receive messages, an application must build a Messaging Service.",
      "The Solace Messaging Service is the basis for all client communication with Solace messaging.",
    ],
    code: [
      {
        protocol: "SMF",
      },
      {
        protocol: "Secured SMF",
      },
      {
        protocol: "Compressed SMF",
      },
    ],
    conclusion: [
      "At this point your client is connected to your Solace PubSub+ Event Broker",
    ],
  },
  step3: {
    tutorials: [
      {
        id: "publish-subscribe",
        name: "Publish/ <br>\
                        Subscribe",
        logo: "./images/publish-subscribe.svg",
        url: "https://github.com/SolaceSamples/solace-samples-java/blob/main/src/main/java/com/solace/samples/java/HelloWorld.java",
      },
      {
        id: "guaranteed-publisher",
        name: "Guaranteed <br>\
                        Publisher",
        logo: "./images/persistence-queues.svg",
        url:
          "https://github.com/SolaceSamples/solace-samples-java/blob/main/src/main/java/com/solace/samples/java/patterns/GuaranteedBlockingPublisher.java",
      },
      {
        id: "guaranteed-subscriber",
        name: "Guaranteed <br>\
                        Subscriber",
        logo: "./images/persistence-queues.svg",
        url:
          "https://github.com/SolaceSamples/solace-samples-java/blob/main/src/main/java/com/solace/samples/java/patterns/GuaranteedSubscriber.java",
      },
    ],
  },
}
